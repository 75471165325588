import axios from "@axios";

import {
    defaultList,
    fetchAll,
} from "@/store/services";

const link = "/calendar/store-planning";

function fetchAllPlannings(payload) {
    return fetchAll("plannings", payload);
}

function list(params) {
    return defaultList(link, params);
}

function get(payload) {
    return defaultGet(link, payload);
}

function add(payload) {
    return defaultAdd(link, payload.data);
}

function update(payload) {
    return defaultUpdate(link, { id: payload.id, ...payload.data });
}

function destroy(brandID) {
    return defaultDestroy(link, brandID);
}

export default {
    fetchAllPlannings,
    list,
    get,
    add,
    update,
    destroy,
};
