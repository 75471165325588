import {
    defaultList,
    fetchAll,
    defaultAdd,
    defaultGet,
    defaultUpdate,
    defaultDestroy, options,
} from "@/store/services";
import axios from "@/libs/axios";

const link = "human-resource/timesheets";

function fetchAllTimesheets(payload) {
    return fetchAll(link, payload);
}

function fetchAllActivityOptions(payload) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/fetch/${link}/activities`, options)
            .then((response) => resolve(response.data))
            .catch(({ response }) => reject(response.data));
    });
}

function list(params) {
    return defaultList(link, params);
}

function get(payload) {
    return defaultGet(link, payload);
}

function add(payload) {
    return defaultAdd(link, payload);
}

function update(payload) {
    return defaultUpdate(link, { id: payload.uid, ...payload });
}

function destroy(timesheetID) {
    return defaultDestroy(link, timesheetID);
}

export default {
    fetchAllTimesheets,
    fetchAllActivityOptions,
    list,
    get,
    add,
    update,
    destroy,
};
