import service from "@/store/services/calendar-service";
import {handleErrors} from "@/utils/handleApiResponse";
import log from "echarts/src/scale/Log";

const state = {
    calendarOptions: [
        {
            color: 'danger',
            label: 'Magasin',
        },
        {
            color: 'primary',
            label: 'Projet',
        },
        {
            color: 'warning',
            label: 'RH',
        },
        {
            color: 'success',
            label: 'Tache',
        },
        // {
        //     color: 'info',
        //     label: 'Catégorie 5',
        // },
    ],
    selectedCalendars: [],
};
const getters = {};
const mutations = {
    SET_SELECTED_EVENTS(state, val) {
        state.selectedCalendars = val
    },
};
const actions = {
    async allPlannings(ctx, {calendars}) {
        try {
            return await service.fetchAllPlannings(calendars);
        } catch ({response}) {
            handleErrors(
                ctx,
                response,
                "Un erreur est survenue lors de la récupération des évènements."
            );
        }
        return new Promise((resolve, reject) => {
            axios
                .get('/apps/calendar/events', {
                    params: {
                        calendars: calendars.join(','),
                    },
                })
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    },
};

const calendar = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default calendar;
