import service from "@/store/services/stocks-service";
import { handleErrors } from "@/utils/handleApiResponse";

const state = {
  list: [],
  stock: null,
  product: null,
  stock_products: [],
  meta: {},
  errors: null,
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, stock) => {
    state.stock = stock;
    state.stock_products = stock.products;

    delete state.stock.products;
  },
  SET_CURRENT_PRODUCT: (state, product) => {
    state.product = product;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_ERRORS: (state, errors) => {
    state.errors = errors;
  },
};

const actions = {
  async allStocks(ctx, params) {
    try {
      const response = await service.fetchAllStocks(params);
      ctx.commit("SET_LIST", response);
    } catch ({ response }) {
      handleErrors(
        ctx,
        response,
        "Une erreur est survenue lors de la récupération des stocks."
      );
    }
  },
  async fetchStock(ctx, params) {
    try {
      const stockData = await service.get(params);
      ctx.commit("SET_RESOURCE", stockData);
    } catch ({ response }) {
      handleErrors(
        ctx,
        response,
        "Un erreur est survenue lors de la récupération du stock."
      );
    }
  },
  async fetchItem(ctx, payload) {
    try {
      const { data } = await service.stockItem(payload);
      data.resource.image = require("@/assets/images/pages/store/product-icon.png");
      ctx.commit("SET_CURRENT_PRODUCT", data.resource);
      return data;
    } catch (error) {
      return error;
    }
  },
  async addStock(ctx, params) {
    return service
      .add(params)
      .then((response) => {
        ctx.commit("SET_RESOURCE", response);
        response.message = "Votre stock a été crée avec succès.";
        return response;
      })
      .catch((e) => {
        handleErrors(ctx, e.response);
      });
  },
  async updateStock(ctx, params) {
    return service
      .update(params)
      .then((response) => {
        response.message = "Votre stock a été modifié avec succès.";
        ctx.commit("SET_RESOURCE", response.resource);
        return response;
      })
      .catch(({ response }) => {
        handleErrors(ctx, response);
      });
  },
  async destroyStock({ commit, dispatch }, params) {
    return service
      .destroy(params)
      .then((response) => response)
      .catch((error) => error);
  },
  async saveProviding(ctx, params) {
    const { store, stock, data } = params;
    return service
      .provide(store, stock, data)
      .then((response) => {
        ctx.commit("SET_RESOURCE", response);
        response.message =
          "Votre approvisionnement a été enregistré avec succès.";
        return response;
      })
      .catch((e) => {
        handleErrors(ctx, e.response);
      });
  },
  async saveProductsRelease(ctx, params) {
    ctx.commit("SET_ERRORS", null)
    return service
        .release(params)
        .then((response) => {
          ctx.commit("SET_RESOURCE", response);
          response.message =
              "Votre sortie a été enregistrée avec succès.";
          return response;
        })
        .catch((e) => {
          handleErrors(ctx, e.response);
        });
  },
};

const getters = {
  all: (state) => state.list,
  stock: (state) => state.stock,
  products: (state) => state.stock_products,
  meta: (state) => state.meta,
  errors: (state) => state.errors,
};

const stock = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default stock;
