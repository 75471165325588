import {
    options,
    defaultList,
    fetchAll,
    defaultAdd,
    defaultGet,
    defaultUpdate,
    defaultDestroy,
} from "@/store/services";
import axios from "@/libs/axios";

const link = "stock-management/stores";

function fetchAllStocks(storeID) {
    const customLink = `${link}/${storeID}/stocks`;
    return fetchAll(customLink);
}

function get(payload) {
    const customLink = `${link}/${payload.store_id}/stocks`;
    return defaultGet(customLink, payload.data);
}

function stockItem({uid, stock, store}) {
    return new Promise((resolve, reject) => {
        axios
            .get(`fetch/${link}/${store}/stocks/${stock}/products/${uid}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

function add(payload) {
    const customLink = `${link}/${payload.store_id}/stocks`;
    return defaultAdd(customLink, payload.data);
}

function update(payload) {
    const customLink = `${link}/${payload.store_id}/stocks`;
    return defaultUpdate(customLink, {id: payload.id, ...payload.data});
}

function destroy(storeID, stockID) {
    const customLink = `${link}/${storeID}/stocks`;
    return defaultDestroy(customLink, stockID);
}

function provide(storeID, stockID, payload) {
    return new Promise((resolve, reject) => {
        axios
            .post(
                `stock-management/supplies/stores/${storeID}/stocks/${stockID}`,
                payload,
                options
            )
            .then((response) => resolve(response.data.resource))
            .catch((error) => reject(error));
    });
}

function release(data) {
    return new Promise((resolve, reject) => {
        axios
            .post("stock-management/stock-releases", data, options)
            .then((response) => resolve(response.data.resource))
            .catch((error) => reject(error));
    });
}

export default {
    add,
    destroy,
    fetchAllStocks,
    get,
    provide,
    release,
    stockItem,
    update,
};
