import FileManagerLayout from "@/views/apps/file-manager/FileManager.vue";
import FullLayout from "@/layouts/full/LayoutFull.vue";
// Auth
const Login = () =>
    import(/* webpackChunkName: "login" */ "@/views/authentication/Login.vue");
const ForgotPassword = () =>
    import(
        /* webpackChunkName: "forgot-password" */ "@/views/authentication/password/ForgotPassword.vue"
        );
const ResetPassword = () =>
    import(
        /* webpackChunkName: "forgot-password" */ "@/views/authentication/password/ResetPassword.vue"
        );

// Miscellaneous
const ComingSoon = () =>
    import(
        /* webpackChunkName: "forgot-password" */ "@/views/miscellaneous/ComingSoon.vue"
        );
const NotAuthorized = () =>
    import(
        /* webpackChunkName: "forgot-password" */ "@/views/miscellaneous/NotAuthorized.vue"
        );
const Error = () =>
    import(
        /* webpackChunkName: "error" */ "@/views/miscellaneous/error/Error404.vue"
        );

// Pages
const Dashboard = () =>
    import(
        /* webpackChunkName: "dashboard" */ "@/views/dashboard/analytics/Analytic.vue"
        );

// Files manager
const MainFileManager = () =>
    import(
        /* webpackChunckName: "file-manager-home" */ "@/views/apps/file-manager/handlers/MainFileManager.vue"
        );
const ShowFolder = () =>
    import(
        /* webpackChunckName: "folder" */ "@/views/apps/file-manager/handlers/ShowFolder.vue"
        );

// Projects management
const ProjectsList = () =>
    import(
        /* webpackChunckName: "projects-list" */ "@/views/apps/project/projects-list/ProjectsList.vue"
        );
const ProjectsView = () =>
    import(
        /* webpackChunckName: "projects-view" */ "@/views/apps/project/projects-view/ProjectsView.vue"
        );
const ProjectsBudgetTracking = () =>
    import(
        /* webpackChunckName: "projects-budget-tracking" */ "@/views/apps/project/projects-view/ProjectViewProjectBudgetTrancking.vue"
        );
const RealTrackingBudget = () =>
    import(
        /* webpackChunckName: "projects-real-tracking" */ "@/views/apps/project/projects-view/budget-tracking/RealTracking.vue"
        );
const ProjectsPaymentStatus = () =>
    import(
        /* webpackChunckName: "projects-list" */ "@/views/apps/project/projects-view/payment-status/PaymentStatusView.vue"
        );
const ProjectsEdit = () =>
    import(
        /* webpackChunckName: "projects-list" */ "@/views/apps/project/projects-edit/ProjectsEdit.vue"
        );

// Employees management
const EmployeesList = () =>
    import(
        /* webpackChunckName: "employees-list" */ "@/views/apps/employee/employees-list/EmployeesList.vue"
        );
const EmployeesAdd = () =>
    import(
        /* webpackChunckName: "employees-add" */ "@/views/apps/employee/employees-list/EmployeeListAddNew.vue"
        );
const EmployeesView = () =>
    import(
        /* webpackChunckName: "employees-view" */ "@/views/apps/employee/employees-view/EmployeesView.vue"
        );
const EmployeesEdit = () =>
    import(
        /* webpackChunckName: "employees-edit" */ "@/views/apps/employee/employees-edit/EmployeesEdit.vue"
        );
const EmployeesSalaryStatement = () =>
    import(
        /* webpackChunckName: "employees-salary-statement" */ "@/views/apps/employee/employees-salary-statement/EmployeesSalaryStatement.vue"
        );
const EmployeesLeaves = () =>
    import(
        /* webpackChunckName: "employees-leaves" */ "@/views/apps/employee/employees-leaves/EmployeesLeaves.vue"
        );

// Roles
const RolesList = () =>
    import(
        /* webpackChunckName: "roles-list" */ "@/views/apps/role/roles-list/RolesList.vue"
        );
const RoleHandle = () =>
    import(
        /* webpackChunckName: "handle-role" */ "@/views/apps/role/handle-role/HandleRole.vue"
        );

// Fund histories
const FundHistoriesView = () =>
    import(
        /* webpackChunckName: "fund-history-list" */ "@/views/apps/fund-history/fund-histories-list/FundList.vue"
        );
const StatisticView = () =>
    import(
        /* webpackChunckName: "check-statistic" */ "@/views/apps/statistic/Statistic.vue"
        );

// Project Status
const ProjectStatusList = () =>
    import(
        /* webpackChunckName: "project-status-list" */ "@/views/apps/project-status/project-status-list/ProjectStatusList.vue"
        );
const ProjectStatusHandle = () =>
    import(
        /* webpackChunckName: "handle-project-status" */ "@/views/apps/project-status/handle-project-status/HandleProjectStatus.vue"
        );

// Taxes
const TaxesList = () =>
    import(
        /* webpackChunckName: "tax-list" */ "@/views/apps/tax/taxes-list/TaxesList.vue"
        );
const TaxHandle = () =>
    import(
        /* webpackChunckName: "handle-tax" */ "@/views/apps/tax/handle-tax/HandleTax.vue"
        );


// Measures
const MeasuresList = () =>
    import(
        /* webpackChunckName: "measures-list" */ "@/views/apps/measure/measures-list/MeasuresList.vue"
        );
const MeasureHandle = () =>
    import(
        /* webpackChunckName: "handle-measure" */ "@/views/apps/measure/handle-measure/HandleMeasure.vue"
        );

// Item Brands
const ItemBrandsList = () =>
    import(
        /* webpackChunckName: "item-brands-list" */ "@/views/apps/item-brand/item-brands-list/ItemBrandsList.vue"
        );
const ItemBrandHandle = () =>
    import(
        /* webpackChunckName: "item-handle-brand" */ "@/views/apps/item-brand/handle-item-brand/HandleItemBrand.vue"
        );

// Item Categories
const ItemCategoriesList = () =>
    import(
        /* webpackChunckName: "item-categories-list" */ "@/views/apps/item-category/item-categories-list/ItemCategoriesList.vue"
        );
const ItemCategoryHandle = () =>
    import(
        /* webpackChunckName: "handle-item-category" */ "@/views/apps/item-category/handle-item-category/HandleItemCategory.vue"
        );

// Items
const ItemsList = () =>
    import(
        /* webpackChunckName: "apps-items-list" */ "@/views/apps/item/items-list/ItemsList.vue"
        );
const ItemHandle = () =>
    import(
        /* webpackChunckName: "apps-handle-item" */ "@/views/apps/item/handle-item/HandleItem.vue"
        );

// Invoices
const InvoicesList = () =>
    import(
        /* webpackChunckName: "invoices-list" */ "@/views/apps/invoice/invoice-list/InvoiceList.vue"
        );
const InvoicePreview = () =>
    import(
        /* webpackChunckName: "invoice-preview" */ "@/views/apps/invoice/invoice-preview/InvoicePreview.vue"
        );
const InvoiceAdd = () =>
    import(
        /* webpackChunckName: "invoice-add" */ "@/views/apps/invoice/invoice-add/InvoiceAdd.vue"
        );
const InvoiceEdit = () =>
    import(
        /* webpackChunckName: "invoice-edit" */ "@/views/apps/invoice/invoice-edit/InvoiceEdit.vue"
        );

// Store Management
const StoresList = () =>
    import(
        /* webpackChunckName: "stores-list" */ "@/views/apps/store-management/stores/stores-list/StoresList.vue"
        );
const StoreView = () =>
    import(
        /* webpackChunckName: "store-view" */ "@/views/apps/store-management/stores/store-view/store-view-stock-items/StoreViewStockItems.vue"
        );
const StockItemView = () =>
    import(
        /* webpackChunckName: "stock-item-view" */ "@/views/apps/store-management/stores/store-view/store-view-stock-item-details/StoreViewStockItemDetails.vue"
        );
const StockProvideItem = () =>
    import(
        /* webpackChunckName: "stock-provide-item" */ "@/views/apps/store-management/stores/store-view/store-view-stock-items/StoreViewStockItemsProvideItem.vue"
        );
const StoreQueueItems = () =>
    import(
        /* webpackChunckName: "store-queue-items" */ "@/views/apps/store-management/stores/store-view/store-view-stock-item-details/StoreViewStockItemDetails.vue"
        );
const StoreItemsCheckout = () =>
    import(
        /* webpackChunckName: "store-checkout-items" */ "@/views/apps/store-management/stores/store-view/store-view-items-checkout/StoreViewItemsCheckout.vue"
        );
const StoreHandle = () =>
    import(
        /* webpackChunckName: "handle-store" */ "@/views/apps/store-management/stores/handle-store/HandleStore.vue"
        );

const Timesheet = () =>
    import(
        /* webpackChunkName: "time-sheet" */ "@/views/apps/timesheet/Timesheet.vue"
        );

//app files manager
let filesManager = {
    path: "/apps/explorateur-de-fichiers",
    component: FileManagerLayout,
    name: "files-manager",
    meta: {
        action: "read_archives",
        resource: "Bibliothèque",
    },
    redirect: "/apps/explorateur-de-fichiers/acceuil",
    children: [
        {
            path: "acceuil",
            name: "files-manager-home",
            component: MainFileManager,
            meta: {
                contentRenderer: "sidebar-left",
                contentClass: "file-manager-application",
                action: "read_archives",
                resource: "Bibliothèque",
            },
        },
        {
            path: "folder/:folder",
            name: "folder-detail",
            component: ShowFolder,
            meta: {
                contentRenderer: "sidebar-left",
                contentClass: "file-manager-application",
                action: "read_archives",
                resource: "Bibliothèque",
            },
        },
    ],
};

// Projects management
let projects = [
    {
        path: "/apps/projets/liste",
        component: ProjectsList,
        name: "projects-list",
        meta: {
            pageTitle: "Liste Projets",
            breadcrumb: [
                {
                    text: "Projets",
                    to: "liste",
                },
                {
                    text: "Liste",
                    active: true,
                },
            ],
            action: "read_all_project",
            resource: "Projets",
        },
    },
    {
        path: "/apps/projets/details/:id",
        component: ProjectsView,
        name: "projects-view",
        meta: {
            pageTitle: "Détails Projet",
            breadcrumb: [
                {
                    text: "Projets",
                    to: {name: "projects-list"},
                },
                {
                    text: "Détails",
                    active: true,
                },
            ],
            action: "read_project",
            resource: "Projets",
        },
    },
    {
        path: "/apps/projets/details/:id/etat-paiements",
        component: ProjectsPaymentStatus,
        name: "project-payment-status",
        meta: {
            pageTitle: "Paiements",
            breadcrumb: [
                {
                    text: "Projets",
                    to: {name: "projects-list"},
                },
                {
                    text: "Détails",
                    to: "projects-view",
                    hasParams: "id",
                },
                {
                    text: "État Paiements",
                    active: true,
                },
            ],
            action: "track_payments",
            resource: "Projets",
        },
    },
    {
        path: "/apps/projets/details/:id/suivi-budget/ajout",
        component: ProjectsBudgetTracking,
        name: "budget-trancking-add",
        meta: {
            pageTitle: "Vendu",
            breadcrumb: [
                {
                    text: "Projets",
                    to: {name: "projects-list"},
                },
                {
                    text: "Détails",
                    to: "projects-view",
                    hasParams: "id",
                },
                {
                    text: "Suivi Budget",
                    active: true,
                },
            ],
            action: "track_budget",
            resource: "Projets",
        },
        props: true,
    },
    {
        path: "/apps/projets/details/:id/suivi-budget/reel",
        component: RealTrackingBudget,
        name: "tracking-real",
        meta: {
            pageTitle: "Suivi Réel",
            breadcrumb: [
                {
                    text: "Projets",
                    to: {name: "projects-list"},
                },
                {
                    text: "Détails",
                    to: "projects-view",
                    hasParams: "id",
                },
                {
                    text: "Suivi Réel",
                    active: true,
                },
            ],
            // action: 'track_real',
            action: "track_budget",
            resource: "Projets",
        },
        props: true,
    },
    {
        path: "/apps/projets/modifier/:id",
        component: ProjectsEdit,
        name: "projects-edit",
        meta: {
            pageTitle: "Paiements",
            breadcrumb: [
                {
                    text: "Projets",
                    to: {name: "projects-list"},
                },
                {
                    text: "Détails",
                    to: "projects-view",
                    hasParams: "id",
                },
                {
                    text: "Modifier Projet",
                    active: true,
                },
            ],
            action: "edit_project",
            resource: "Projets",
        },
    },
];

// *===============================================---*
// *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
// *===============================================---*
let todos = [
    {
        path: "/apps/gestion-taches",
        name: "todos",
        // component: ComingSoon,
        component: () =>
            import(
                /* webpackChunckName: "todos-list" */ "@/views/apps/todo/Todo.vue"
                ),
        meta: {
            contentRenderer: "sidebar-left",
            contentClass: "todo-application",
            action: "read_all_tasks",
            resource: "Tâches",
        },
    },
    {
        path: "/apps/gestion-taches/:filter",
        name: "todo-filter",
        component: () =>
            import(
                /* webpackChunckName: "todos-filter" */ "@/views/apps/todo/Todo.vue"
                ),
        meta: {
            contentRenderer: "sidebar-left",
            contentClass: "todo-application",
            navActiveLink: "apps-todo",
            action: "read_all_tasks",
            resource: "Tâches",
        },
        beforeEnter(to, _, next) {
            if (["active", "pending", "ending", "done"].includes(to.params.filter))
                next();
            else next({name: "error-404"});
        },
    },
    {
        path: "/apps/gestion-taches/statut/:status",
        name: "todo-status",
        component: () =>
            import(
                /* webpackChunckName: "todos-status" */ "@/views/apps/todo/Todo.vue"
                ),
        meta: {
            contentRenderer: "sidebar-left",
            contentClass: "todo-application",
            navActiveLink: "apps-todo",
            action: "read_all_tasks",
            resource: "Tâches",
        },
        beforeEnter(to, _, next) {
            if (["active", "pending", "ending", "done"].includes(to.params.status))
                next();
            else next({name: "error-404"});
        },
    },
];

// *===============================================---*
// *--------- EMPLOYEE ---- ---------------------------------------*
// *===============================================---*
let employees = [
    {
        path: "/apps/gestion-personnels/liste",
        name: "apps-employees-list",
        component: EmployeesList,
        meta: {
            pageTitle: "Liste Personnels",
            breadcrumb: [
                {
                    text: "Personnels",
                    to: "liste",
                },
                {
                    text: "Liste",
                    active: true,
                },
            ],
            action: "read_all_employees",
            resource: "Employés",
        },
    },
    {
        path: "/apps/gestion-personnels/ajouter",
        name: "apps-employees-add",
        component: EmployeesAdd,
        meta: {
            pageTitle: "Ajouter Personnel",
            breadcrumb: [
                {
                    text: "Personnels",
                    to: {name: "apps-employees-list"},
                },
                {
                    text: "Ajouter",
                    active: true,
                },
            ],
            action: "add_employee",
            resource: "Employés",
        },
    },
    {
        path: "/apps/gestion-personnels/:id/details",
        name: "apps-employees-view",
        component: EmployeesView,
        meta: {
            pageTitle: "Détails Personnel",
            breadcrumb: [
                {
                    text: "Personnels",
                    to: {name: "apps-employees-list"},
                },
                {
                    text: "Détails",
                    active: true,
                },
            ],
            action: "read_employee",
            resource: "Employés",
        },
    },
    {
        path: "/apps/gestion-personnels/:id/modifier",
        name: "apps-employees-edit",
        component: EmployeesEdit,
        meta: {
            pageTitle: "Modifier Personnel",
            breadcrumb: [
                {
                    text: "Personnels",
                    to: {name: "apps-employees-list"},
                },
                {
                    text: "Détails",
                    to: "apps-employees-view",
                    hasParams: "id",
                },
                {
                    text: "Modifier",
                    active: true,
                },
            ],
            action: "edit_employee",
            resource: "Employés",
        },
    },
    {
        path: "/apps/gestion-personnels/:id/etat-salaire",
        name: "apps-employees-salary-statement",
        component: EmployeesSalaryStatement,
        meta: {
            pageTitle: "État de Salaire",
            breadcrumb: [
                {
                    text: "Personnels",
                    to: {name: "apps-employees-list"},
                },
                {
                    text: "Détails",
                    to: "apps-employees-view",
                    hasParams: "id",
                },
                {
                    text: "État de Salaire",
                    active: true,
                },
            ],
            action: "edit_employee",
            resource: "Employés",
        },
    },
    {
        path: "/apps/gestion-personnels/:id/conges",
        name: "apps-employees-leaves",
        component: EmployeesLeaves,
        meta: {
            pageTitle: "Congés",
            breadcrumb: [
                {
                    text: "Personnels",
                    to: {name: "apps-employees-list"},
                },
                {
                    text: "Détails",
                    to: "apps-employees-view",
                    hasParams: "id",
                },
                {
                    text: "Congés",
                    active: true,
                },
            ],
            action: "edit_employee",
            resource: "Employés",
        },
    },
];

// *===============================================---*
// *--------- USER ---- ---------------------------------------*
// *===============================================---*
let users = [
    {
        path: "/apps/gestion-utilisateurs/liste",
        name: "apps-users-list",
        component: () => import("@/views/apps/user/users-list/UsersList.vue"),
        meta: {
            pageTitle: "Liste Utilisateurs",
            breadcrumb: [
                {
                    text: "Utilisateurs",
                    to: "liste",
                },
                {
                    text: "Liste",
                    active: true,
                },
            ],
            action: "read_users",
            resource: "Rôles",
        },
    },
    {
        path: "/apps/parametres",
        name: "apps-account-setting",
        component: () => import("@/views/apps/account-setting/AccountSetting.vue"),
        meta: {
            pageTitle: "Paramètres Compte",
            breadcrumb: [
                {
                    text: "Acceuil",
                    to: "dashboard",
                },
                {
                    text: "Paramètres Compte",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/apps/feuille-de-temps",
        name: "apps-timesheet",
        component: Timesheet,
        meta: {
            pageTitle: "Feuille de temps",
            breadcrumb: [
                {
                    text: "Acceuil",
                    to: "dashboard",
                },
                {
                    text: "Feuille de temps",
                    active: true,
                },
            ],
            action: "read_all_timesheets",
            resource: "Feuilles de temps",
        },
    },
    {
        path: "/apps/gestion-utilisateurs/details/:id",
        name: "apps-users-view",
        component: () => import("@/views/apps/user/users-view/UsersView.vue"),
        meta: {
            action: "read_users",
            resource: "Rôles",
        },
    },
    {
        path: "/apps/gestion-utilisateurs/modifier/:id",
        name: "apps-users-edit",
        component: () => import("@/views/apps/user/users-edit/UsersEdit.vue"),
        meta: {
            action: "edit_user",
            resource: "Utilisateurs",
        },
    },
];

// *===============================================---*
// *--------- ROLE ---- ---------------------------------------*
// *===============================================---*
let roles = [
    {
        path: "/apps/gestion-roles/liste",
        name: "apps-roles-list",
        component: RolesList,
        meta: {
            pageTitle: "Liste Rôles",
            breadcrumb: [
                {
                    text: "Rôles",
                    to: "liste",
                },
                {
                    text: "Liste",
                    active: true,
                },
            ],
            action: "read_users",
            resource: "Rôles",
        },
    },
    {
        path: "/apps/gestion-roles/ajouter",
        name: "apps-roles-add",
        component: RoleHandle,
        meta: {
            action: "read_users",
            resource: "Rôles",
        },
    },
    {
        path: "/apps/gestion-roles/modifier/:id",
        name: "apps-roles-edit",
        component: RoleHandle,
        meta: {
            action: "read_users",
            resource: "Rôles",
        },
    },
];

// *===============================================---*
// *--------- FINANCE ---- ---------------------------------------*
// *===============================================---*

// Fund Histories
let fundHistories = [
    {
        path: "/apps/finances/caisse-generale",
        name: "fund-histories-list",
        component: FundHistoriesView,
        meta: {
            pageTitle: "Caisse Générale",
            breadcrumb: [
                {
                    text: "Caisse",
                    to: "caisse-generale",
                },
                {
                    text: "Historique",
                    active: true,
                },
            ],
            action: "read_transactions_fund_history",
            resource: "Caisse Générale",
        },
    },
    {
        path: "/apps/finances/statistiques",
        name: "apps-statistic",
        component: StatisticView,
        meta: {
            pageTitle: "Statistiques",
            breadcrumb: [
                {
                    text: "Statistiques",
                    active: true,
                },
            ],
            action: "read_statistic",
            resource: "Statistiques",
        },
    },
];
// Invoice
let invoices = [
    {
        path: "/apps/finances/facturation",
        name: "apps-invoice-list",
        component: InvoicesList,
        meta: {
            pageTitle: "Liste Factures",
            breadcrumb: [
                {
                    text: "Facturation",
                    to: "facturation",
                },
                {
                    text: "Liste",
                    active: true,
                },
            ],
            action: "read_all_invoices",
            resource: "Facturation",
        },
    },
    {
        path: "/apps/finances/facturation/details/:id",
        name: "apps-invoice-preview",
        component: InvoicePreview,
        meta: {
            pageTitle: "Détails Facture",
            breadcrumb: [
                {
                    text: "Facturation",
                    to: {name: "apps-invoice-list"},
                },
                {
                    text: "Détails",
                    active: true,
                },
            ],
            action: "read_invoice",
            resource: "Facturation",
        },
    },
    {
        path: "/apps/finances/facturation/ajouter",
        name: "apps-invoice-add",
        component: InvoiceAdd,
        meta: {
            pageTitle: "Ajouter Facture",
            breadcrumb: [
                {
                    text: "Facturation",
                    to: {name: "apps-invoice-list"},
                },
                {
                    text: "Ajouter Facture",
                    active: true,
                },
            ],
            action: "add_invoice",
            resource: "Facturation",
        },
    },
    {
        path: "/apps/finances/facturation/modifier/:id",
        name: "apps-invoice-edit",
        component: InvoiceEdit,
        meta: {
            pageTitle: "Modifier Facture",
            breadcrumb: [
                {
                    text: "Facturation",
                    to: {name: "apps-invoice-list"},
                },
                {
                    text: "Détails",
                    to: "apps-invoice-preview",
                    hasParams: "id",
                },
                {
                    text: "Modifier Facture",
                    active: true,
                },
            ],
            action: "edit_invoice",
            resource: "Facturation",
        },
    },
];

// *===============================================---*
// *--------- STOCK MANAGEMENT ---------------------------------------*
// *===============================================---*
let storeManagement = [
    {
        path: "/apps/magasins",
        name: "apps-stores",
        redirect: {name: "apps-stores-list"},
    },
    {
        path: "/apps/gestion-magasins/liste",
        name: "apps-stores-list",
        component: StoresList,
        meta: {
            pageTitle: "Liste Magasins",
            breadcrumb: [
                {
                    text: "Magasins",
                    to: "liste",
                },
                {
                    text: "Liste",
                    active: true,
                },
            ],
            action: "read_all_stores",
            resource: "Magasins",
        },
    },
    {
        path: "/apps/gestion-magasins/ajouter",
        name: "apps-store-add",
        component: StoreHandle,
        meta: {
            action: "add_store",
            resource: "Magasins",
        },
    },
    {
        path: "/apps/gestion-magasins/modifier/:id",
        name: "apps-store-edit",
        component: StoreHandle,
        meta: {
            action: "edit_store",
            resource: "Magasins",
        },
    },
    {
        path: "/apps/gestion-magasins/details/:id",
        name: "apps-store-details",
        component: StoreView,
        meta: {
            contentRenderer: "sidebar-left-detached",
            contentClass: "ecommerce-application",
            pageTitle: "Détails",
            breadcrumb: [
                {
                    text: "Magasins",
                    to: "liste",
                },
                {
                    text: "Détails",
                    active: true,
                },
            ],
            action: "read_store",
            resource: "Magasins",
        },
    },
    {
        path: "/apps/gestion-magasins/stocks/:id/produits/:slug",
        name: "apps-stock-product-details",
        component: StockItemView,
        meta: {
            pageTitle: "Détails Produit",
            contentClass: "ecommerce-application",
            breadcrumb: [
                {
                    text: "Magasins",
                    to: "liste",
                },
                {
                    text: "Détails",
                    to: {name: "apps-store-details", hasParams: 'id'},
                },
                {
                    text: "Stock",
                    active: true,
                },
                {
                    text: "Détails Produit",
                    active: true,
                },
            ],
            action: "read_product",
            resource: "Produits",
        },
    },
    {
        path: "/apps/gestion-magasins/:storeID/stocks/:id/provide",
        name: "apps-stock-provide",
        component: StockProvideItem,
        meta: {
            pageTitle: "Approvisionnement",
            contentClass: "ecommerce-application",
            breadcrumb: [
                {
                    text: "Magasins",
                    to: "liste",
                },
                {
                    text: "Détails",
                    to: {name: "apps-store-details", hasParams: "id"},
                },
                {
                    text: "Stock",
                    active: true,
                },
                {
                    text: "Approvisionnement",
                    active: true,
                },
            ],
            action: "read_product",
            resource: "Produits",
        },
    },
    {
        path: "/apps/gestion-magasins/magasins/:id/sortie",
        name: "apps-store-product-checkout",
        component: StoreItemsCheckout,
        meta: {
            pageTitle: "Sortie du Magasin",
            contentClass: "ecommerce-application",
            breadcrumb: [
                {
                    text: "Magasins",
                    to: "liste",
                },
                {
                    text: "Détails",
                    to: {name: "apps-store-details", hasParams: 'id'},
                },
                {
                    text: "Planification",
                    active: true,
                },
            ],
            action: "edit_store",
            resource: "Magasins",
        },
    },
];
// let storeDetails = [
//   {
//     path: "/apps/e-commerce/wishlist",
//     name: "apps-e-commerce-wishlist",
//     component: () =>
//       import("@/views/apps/store-management/e-commerce-wishlist/ECommerceWishlist.vue"),
//     meta: {
//       pageTitle: "Wishlist",
//       contentClass: "ecommerce-application",
//       breadcrumb: [
//         {
//           text: "ECommerce",
//         },
//         {
//           text: "Wishlist",
//           active: true,
//         },
//       ],
//       action: "read_all_categories",
//       resource: "Catégories",
//     },
//   },
// ];

// let stocks = [
//   {
//     path: "/apps/gestion-stock-magasins/liste",
//     name: "apps-stocks-list",
//     component: StocksList,
//     meta: {
//       pageTitle: "Liste Stocks",
//       breadcrumb: [
//         {
//           text: "Stocks",
//           to: "liste"
//         },
//         {
//           text: "Liste",
//           active: true
//         },
//       ],
//       action: "read_stocks_management",
//       resource: "Stocks"
//     },
//   }
// ];

// let productItems = [
//   {
//     path: "/apps/gestion-produits/liste",
//     name: "apps-items-list",
//     component: ProductsList,
//     meta: {
//       pageTitle: "Liste Produits",
//       breadcrumb: [
//         {
//           text: "Produits",
//           to: "liste"
//         },
//         {
//           text: "Liste",
//           active: true
//         },
//       ],
//       action: "read_items_management",
//       resource: "Produits"
//     },
//   }
// ];

// Calendar
let calendar = [
    {
        path: "/apps/calendrier",
        name: "apps-calendar",
        component: () => import("@/views/apps/calendar/Calendar.vue"),
    },
];
// *===============================================---*
// *--------- SETTINGS ---- ---------------------------------------*
// *===============================================---*
let projectStatus = [
    {
        path: "/apps/gestion-statuts-projets/liste",
        name: "apps-project-status-list",
        component: ProjectStatusList,
        meta: {
            pageTitle: "Liste Statuts Projets",
            breadcrumb: [
                {
                    text: "Statuts Projets",
                    to: "liste",
                },
                {
                    text: "Liste",
                    active: true,
                },
            ],
            action: "read_project_status",
            resource: "Statut Projet",
        },
    },
    {
        path: "/apps/gestion-statuts-projets/ajouter",
        name: "apps-project-status-add",
        component: ProjectStatusHandle,
        meta: {
            action: "add_project_status",
            resource: "Statut Projet",
        },
    },
    {
        path: "/apps/gestion-statuts-projets/modifier/:id",
        name: "apps-project-status-edit",
        component: ProjectStatusHandle,
        meta: {
            action: "edit_project_status",
            resource: "Statut Projet",
        },
    },
];

let taxes = [
    {
        path: "/apps/gestion-taxes/liste",
        name: "apps-taxes-list",
        component: TaxesList,
        meta: {
            pageTitle: "Liste Taxes",
            breadcrumb: [
                {
                    text: "Taxes",
                    to: "liste",
                },
                {
                    text: "Liste",
                    active: true,
                },
            ],
            action: "read_taxes",
            resource: "Taxes",
        },
    },
    {
        path: "/apps/gestion-taxes/ajouter",
        name: "apps-tax-add",
        component: TaxHandle,
        meta: {
            action: "add_tax",
            resource: "Taxes",
        },
    },
    {
        path: "/apps/gestion-taxes/modifier/:id",
        name: "apps-tax-edit",
        component: TaxHandle,
        meta: {
            action: "edit_tax",
            resource: "Taxes",
        },
    },
];
let measures = [
    {
        path: "/apps/gestion-mesures/liste",
        name: "apps-measures-list",
        component: MeasuresList,
        meta: {
            pageTitle: "Liste Mesures",
            breadcrumb: [
                {
                    text: "Mesures",
                    to: "liste",
                },
                {
                    text: "Liste",
                    active: true,
                },
            ],
            action: "read_all_measures",
            resource: "Mesures",
        },
    },
    {
        path: "/apps/gestion-mesures/ajouter",
        name: "apps-measure-add",
        component: MeasureHandle,
        meta: {
            action: "add_measure",
            resource: "Mesures",
        },
    },
    {
        path: "/apps/gestion-marques/modifier/:id",
        name: "apps-measure-edit",
        component: MeasureHandle,
        meta: {
            action: "edit_measure",
            resource: "Mesures",
        },
    },
];

let brands = [
    {
        path: "/apps/gestion-marques/liste",
        name: "apps-item-brands-list",
        component: ItemBrandsList,
        meta: {
            pageTitle: "Liste Marques",
            breadcrumb: [
                {
                    text: "Marques",
                    to: "liste",
                },
                {
                    text: "Liste",
                    active: true,
                },
            ],
            action: "read_all_brands",
            resource: "Marques",
        },
    },
    {
        path: "/apps/gestion-marques/ajouter",
        name: "apps-item-brand-add",
        component: ItemBrandHandle,
        meta: {
            action: "add_brand",
            resource: "Marques",
        },
    },
    {
        path: "/apps/gestion-marques/modifier/:id",
        name: "apps-item-brand-edit",
        component: ItemBrandHandle,
        meta: {
            action: "edit_brand",
            resource: "Marques",
        },
    },
];

let categories = [
    {
        path: "/apps/gestion-categories/liste",
        name: "apps-item-categories-list",
        component: ItemCategoriesList,
        meta: {
            pageTitle: "Liste Categories",
            breadcrumb: [
                {
                    text: "Categories",
                    to: "liste",
                },
                {
                    text: "Liste",
                    active: true,
                },
            ],
            action: "read_all_categories",
            resource: "Catégories",
        },
    },
    {
        path: "/apps/gestion-categories/ajouter",
        name: "apps-item-category-add",
        component: ItemCategoryHandle,
        meta: {
            action: "add_category",
            resource: "Catégories",
        },
    },
    {
        path: "/apps/gestion-categories/modifier/:id",
        name: "apps-item-category-edit",
        component: ItemCategoryHandle,
        meta: {
            action: "edit_category",
            resource: "Catégories",
        },
    },
];

let items = [
    {
        path: "/apps/gestion-produits/liste",
        name: "apps-items-list",
        component: ItemsList,
        meta: {
            pageTitle: "Liste Produits",
            breadcrumb: [
                {
                    text: "Produits",
                    to: "liste",
                },
                {
                    text: "Liste",
                    active: true,
                },
            ],
            action: "read_all_products",
            resource: "Produits",
        },
    },
    {
        path: "/apps/gestion-produits/ajouter",
        name: "apps-item-add",
        component: ItemHandle,
        meta: {
            action: "add_product",
            resource: "Produits",
        },
    },
    {
        path: "/apps/gestion-produits/modifier/:id",
        name: "apps-item-edit",
        component: ItemHandle,
        meta: {
            action: "edit_product",
            resource: "Produits",
        },
    },
];

let miscellaneous = {
    path: "/miscellaneous",
    name: "miscellaneous",
    component: FullLayout,
    children: [
        {
            path: "coming-soon",
            name: "coming-soon",
            component: ComingSoon,
        },
        {
            path: "non-authorise",
            name: "not-authorized",
            component: NotAuthorized,
        },
        {
            path: "erreur",
            name: "error",
            component: Error,
        },
    ],
};

const routes = [
    {
        path: "/tableau-de-board",
        name: "dashboard",
        component: Dashboard,
    },
    {
        path: "/",
        redirect: {name: "dashboard"},
    },
    {
        path: "/auth/connexion",
        name: "login",
        component: Login,
        meta: {
            layout: "full",
        },
    },
    // {
    //     path: 'auth/mot-de-passe/email',
    //     name: 'forgot-password',
    //     component: ForgotPassword,
    //     meta: {
    //         layout: 'full',
    //     },
    // },
    // {
    //     path: 'auth/mot-de-passe/oublie',
    //     name: 'reset-password',
    //     component: ResetPassword,
    // },
    filesManager,
    ...projects,
    ...todos,
    ...invoices,
    // ...ecommerce,
    ...fundHistories,
    ...employees,
    ...users,
    ...roles,
    ...projectStatus,
    ...taxes,
    ...categories,
    ...brands,
    ...measures,
    ...storeManagement,
    ...calendar,
    ...items,
    miscellaneous,
    {
        path: "/error-404",
        name: "error-404",
        component: Error,
    },
    {
        path: "*",
        redirect: "error-404",
    },
];

export default routes;
