import service from "@/store/services/timesheets-service";
import {handleErrors} from "@/utils/handleApiResponse";

const state = {
    list: [],
    timesheet: null,
    activityOptions: [],
    meta: {},
    errors: null,
};

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_RESOURCE: (state, timesheet) => {
        state.timesheet = timesheet;
    },
    SET_CURRENT_TIMESHEET: (state, timesheet) => {
        state.timesheet = timesheet;
    },
    SET_ACTIVITY_OPTIONS: (state, acts) => {
        state.activityOptions = acts;
    },
    SET_META: (state, meta) => {
        state.meta = meta;
    },
    SET_ERRORS: (state, errors) => {
        state.errors = errors;
    },
};

const actions = {
    async fetchTimesheets(ctx, params) {
        try {
            const {list, meta} = await service.list(params);
            ctx.commit("SET_LIST", list);
            ctx.commit("SET_META", meta);
            return {timesheets: list, total: meta?.total};
        } catch (error) {
            handleErrors(
                ctx,
                error,
                "Une erreur est survenue lors de la récupération des feuilles de temps."
            );
        }
    },
    async fetchTimesheet(ctx, params) {
        try {
            const timesheetData = await service.get(params);
            ctx.commit("SET_RESOURCE", timesheetData);
        } catch ({response}) {
            handleErrors(
                ctx,
                response,
                "Un erreur est survenue lors de la récupération de la feuille de temps."
            );
        }
    },
    async addTimesheet(ctx, params) {
        return service
            .add(params)
            .then((response) => {
                ctx.commit("SET_RESOURCE", response);
                response.message = "La feuille de temps a été créée avec succès.";
                return response;
            })
            .catch((e) => {
                handleErrors(ctx, e.response);
            });
    },
    async updateTimesheet(ctx, params) {
        return service
            .update(params)
            .then((response) => {
                response.message = "La feuille de temps a été modifiée avec succès.";
                ctx.commit("SET_RESOURCE", response.resource);
                return response;
            })
            .catch(({response}) => {
                handleErrors(ctx, response);
                return response;
            });
    },
    async destroyTimesheet({commit, dispatch}, params) {
        return service
            .destroy(params)
            .then((response) => response)
            .catch((error) => error);
    },
    async fetchActivityOptions(ctx, params) {
        try {
            const response = await service.fetchAllActivityOptions(params);
            ctx.commit("SET_ACTIVITY_OPTIONS", response.data);
        } catch (response) {
            handleErrors(
                ctx,
                response,
                "Un erreur est survenue lors de la récupération des activités."
            );
        }
    },
};

const getters = {
    all: (state) => {
        return state.list.map(ts => {
            return {
                ...ts,
                approved: ts.status === 'validated',
            }
        })
    },
    timesheet: (state) => state.timesheet,
    activityOptions: (state) => state.activityOptions,
    meta: (state) => state.meta,
    errors: (state) => state.errors,
};

const timesheet = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
export default timesheet;
