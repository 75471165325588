import service from "@/store/services/stores-management-service";
import {handleErrors} from "@/utils/handleApiResponse";

const state = {
    list: [],
    store: {},
    product: null,
    productsList: [],
    meta: {},
    errors: null,
    cartItemsCount: (() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        return userData && userData.extras
            ? userData.extras.eCommerceCartItemsCount
            : 0;
    })(),
    userCart: [],
    userWishlist: [],
};

const mutations = {
    SET_LIST: (state, list) => {
        state.list = list;
    },
    SET_PRODUCTS_LIST: (state, list) => {
        state.productsList = list;
    },
    SET_CURRENT_PRODUCT: (state, product) => {
        state.product = product;
    },
    SET_RESOURCE: (state, store) => {
        state.store = store;
    },
    SET_META: (state, meta) => {
        state.meta = meta;
    },
    SET_ERRORS: (state, error) => {
        state.errors = error;
    },
    UPDATE_CART_ITEMS_COUNT(state, count) {
        state.cartItemsCount = count;
    },
    ADD_ITEM_IN_CART(state, {productId}) {
        // Add Item in user Cart
        const {length} = state.userCart;
        let lastId = 0;
        if (length) lastId = state.userCart[length - 1].i;

        state.userCart.push({
            id: lastId + 1,
            product_id: productId,
            qty: 1,
        });
    },
    REMOVE_ITEM_FROM_CART(state, {productId}) {
        const productIndex = state.userCart.findIndex(
            (i) => i.product_id === productId
        );
        if (productIndex > -1) state.userCart.splice(productIndex, 1);
    },
    ADD_ITEM_IN_WISH_LIST(state, {productId}) {
        const {length} = state.userWishlist
        let lastId = 0
        if (length) lastId = state.userWishlist[length - 1].i

        state.userWishlist.push({
            id: lastId + 1,
            product_id: productId,
        })
    },
    REMOVE_ITEM_FROM_WISH_LIST(state, {productId}) {
        const productIndex = state.userWishlist.findIndex(
            (i) => i.product_id === productId
        );
        if (productIndex > -1) state.userWishlist.splice(productIndex, 1);
    },
};

const actions = {
    async allStores(ctx, params) {
        try {
            const service = await service.fetchAllStores(params);
            return service;
        } catch ({response}) {
            handleErrors(
                ctx,
                response,
                "Un erreur est survenue lors de la récupération des magasins."
            );
        }
    },
    async fetchStoresList(ctx, params) {
        try {
            const {list, meta} = await service.list(params);
            ctx.commit("SET_LIST", list);
            ctx.commit("SET_META", meta);
        } catch ({response}) {
            handleErrors(
                ctx,
                response,
                "Un erreur est survenue lors de la récupération des magasins."
            );
        }
    },

    async fetchStore(ctx, params) {
        try {
            const storeData = await service.get(params);
            ctx.commit("SET_RESOURCE", storeData);
        } catch ({response}) {
            handleErrors(
                ctx,
                response,
                "Un erreur est survenue lors de la récupération du magasin."
            );
        }
    },

    async addStore(ctx, params) {
        try {
            const response = await service.add(params);
            ctx.commit("SET_RESOURCE", response);
            return {message: "Votre magasin a été enregistré avec succès!"};
        } catch ({response}) {
            handleErrors(ctx, response);
        }
    },
    async updateStore(ctx, params) {
        try {
            const service = await service.update(params);
            ctx.commit("SET_RESOURCE", service.resource);
            return {message: "Votre magasin a été mis à jour avec succès."};
        } catch ({response}) {
            handleErrors(
                ctx,
                response,
                "Une erreur est survenue lors de la mise à jour du magasin"
            );
        }
    },
    async destroyStore({commit, dispatch}, params) {
        //
    },

    async fetchItems(ctx, payload) {
        try {
            const {list, meta} = await service.fetchAllStoreItems(payload);

            const productsMapped = list.map((p) => {
                p.image = require("@/assets/images/pages/store/product-icon.png");

                return p;
            });
            ctx.commit("SET_PRODUCTS_LIST", productsMapped);
        } catch ({response}) {
            handleErrors(
                ctx,
                response,
                "Une erreur est survenue lors de récupération des produits"
            );
        }
    },

    async fetchQueueItems({commit, dispatch}, params) {
        try {
            const items = await service.queueItems(params);
            return items;
        } catch (error) {
            return error;
        }
    },

    async fetchCartItems({commit, dispatch}, params) {
        try {
            const items = await service.cartItems(params);
            return items;
        } catch (error) {
            return error;
        }
    },

    async addItemToQueue(ctx, {productId}) {
        try {
            const response = await service.addItemToQueue(productId);
            return response;
        } catch (error) {
            return error;
        }
    },
};

const getters = {
    all: (state) => state.list,
    products: (state) => {
        // state.productsList.forEach(product => {
        //   /* eslint-disable no-param-reassign */
        //   product.is_in_wish_list = state.userWishlist.findIndex(p => p.productId === product.id) > -1
        //   product.is_in_cart = state.userCart.findIndex(p => p.productId === product.id) > -1
        //   /* eslint-enable */
        // })

        return state.productsList;
    },
    store: (state) => state.store,
    meta: (state) => state.meta,
    errors: (state) => state.errors,
    cartItems: (state) => {
        return state.userCart.map((cartProduct) => {
            const productsList = state.productsList;
            const product = productsList.find(
                ({details}) => details.id === cartProduct.product_id
            );

            // Other data
            product.details.is_in_wish_list =
                state.userWishlist.findIndex(
                    (p) => p.productId === cartProduct.productId
                ) > -1;
            product.qty = cartProduct.qty;

            return product;
        });
    },
};

const storeManagement = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
export default storeManagement;
