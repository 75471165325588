import service from "@/store/services/invoices-service";

const state = {
  list: [],
  clients: [],
  payment_methods: [],
  projects: [],
  budget: {},
  available_services: [],
  invoice: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, invoice) => {
    state.invoice = invoice;
  },
  SET_CLIENTS: (state, clients) => {
    state.clients = clients;
  },
  SET_PAYMENT_METHODS: (state, payment_methods) => {
    state.payment_methods = payment_methods;
  },
  SET_BUDGET: (state, budget) => {
    state.budget = budget;
  },
  SET_AVAILABLE_SERVICES: (state, services) => {
    state.available_services = services;
  },
  REMOVE_ITEM_FROM_AVAILABLE_SERVICES: (state, serviceUID) => {
    const services = [...state.available_services];
    const serviceIndex = services.findIndex((s) => s.uid == serviceUID);

    if (serviceIndex != -1) services.splice(serviceIndex, 1);

    state.available_services = services;
  },
  UPDATE_ITEM_FROM_AVAILABLE_SERVICES: (state, serviceUID) => {
    const services = [...state.budget.services];
    const serviceFounded = services.find((s) => s.uid == serviceUID);

    if (serviceFounded) state.available_services.unshift(serviceFounded);
  },
  SET_PROJECTS: (state, projects) => {
    state.projects = projects;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

const actions = {
  async list({ commit, dispatch }, params) {
    try {
      const { list, meta } = await service.list(params);
      commit("SET_LIST", list);
      commit("SET_META", meta);
      return { invoices: list, total: meta.total };
    } catch (error) {
      return error;
    }
  },
  async fetchProperties({ commit, dispatch }, params) {
    try {
      const { clients, payment_methods } = await service.getProperties(params);

      if(clients != undefined) commit("SET_CLIENTS", clients);
      if(payment_methods != undefined)commit("SET_PAYMENT_METHODS", payment_methods);
      
    } catch (error) {
      return error;
    }
  },
  async fetchClientProjects({ commit, dispatch }, params) {
    try {
      const projects = await service.getProjects(params);
      commit("SET_PROJECTS", projects);
    } catch (error) {
      return error;
    }
  },
  async fetchProjectBudget({ commit, dispatch }, params) {
    try {
      const { budget } = await service.getBudget(params);

      if (typeof budget === "object" && budget.hasOwnProperty("services")) {
        commit("SET_BUDGET", budget);
        commit("SET_AVAILABLE_SERVICES", budget?.services);
        return { data: budget, success: true };
      } else {
        commit("SET_BUDGET", {});
        return { data: {}, success: true };
      }
    } catch ({ response }) {
      commit("SET_BUDGET", {});
      return { data: response.data, success: false };
    }
  },
  async get({ commit, dispatch }, params) {
    try {
      const invoice = await service.get(params)
      commit("SET_RESOURCE", invoice);
      return invoice
    } catch (error) {
      return ({...error})
    }
  },
  add({ commit, dispatch }, params) {
    return service
      .add(params)
      .then((invoice) => {
        commit('SET_RESOURCE', invoice);
        return invoice;
      })
      .catch((error) => error);
  },
  update({ commit, dispatch }, params) {
    return service.update(params).then((invoice) => {
      commit("SET_RESOURCE", invoice);
    });
  },
  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },
};

const getters = {
  all: (state) => state.list,
  invoice: (state) => state.invoice,
  meta: (state) => state.meta,
  clients: (state) => state.clients,
  paymentMethods: (state) => state.payment_methods,
  projects: (state) => state.projects,
  budget: (state) => state.budget,
  availableServices: (state) => state.available_services,
  getClientByID: (state) => (clientID) => {
    const clientsList = [...state.clients];
    return clientsList.find((c) => (c.id = clientID));
  },
};

const invoices = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
export default invoices;
